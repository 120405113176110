<template>
  <div class="nk-block">
    <div class="nk-block-head">
      <h5>{{ $t('Personal Information') }}</h5>
      <p>{{ $t('User basic & contact information.')}}</p>
    </div>
    <row class="g-3">
      <column xl="3" lg="2">
        <span class="sub-text">{{$t('First Name')}}</span>
        <span class="profile-ud-value">{{userData.first_name}}</span>
      </column>
      <column xl="3" lg="2">
        <span class="sub-text">{{$t('Last Name')}}</span>
        <span class="profile-ud-value">{{userData.last_name}}</span>
      </column>
    </row>
  </div>
  <div class="nk-block">
    <div class="nk-block-head nk-block-head-line">
      <h6 class="title overline-title text-base">{{ $t('Contact Information') }}</h6>
    </div>
    <row class="g-3">
      <column xl="3" lg="2">
        <span class="sub-text">{{$t('Phone number')}}</span>
        <span class="profile-ud-value">{{userData.phone_number}}</span>
      </column>
      <column xl="3" lg="2">
        <span class="sub-text">{{$t('Postcode')}}</span>
        <span class="profile-ud-value">{{userData.post_code}}</span>
      </column>
      <column xl="3" lg="2">
        <span class="sub-text">{{$t('City')}}</span>
        <span class="profile-ud-value">{{userData.city}}</span>
      </column>
      <column xl="3" lg="2">
        <span class="sub-text">{{$t('Province')}}</span>
        <span class="profile-ud-value">{{userData.province}}</span>
      </column>
      <column xl="3" lg="2">
        <span class="sub-text">{{$t('Address')}}</span>
        <span class="profile-ud-value">{{userData.house_no}}, {{userData.street}}</span>
      </column>
    </row>
  </div>
</template>

<script>
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
export default {
  components: {Column, Row},
  props: {
    userData:{
      required: true,
      type: Object,
    }
  }
}
</script>
