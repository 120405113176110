<template>
  <div class="nk-block">
    <div class="nk-block-head">
      <h5>{{ $t('Statistics') }}</h5>
    </div>
    <row class="g-gs">
      <column sm="6" lg="4">
        <card class="nk-ecwg bg-primary-dim p-3" no-padding>
          <card-title-group :card-title="$t('Assigned Leads')"></card-title-group>
          <card-data :amount="statistics.assigned_leads">
            <template v-slot:info>
              <span>{{$t('Assigned Leads')}}</span>
            </template>
          </card-data>
        </card>
      </column>
      <column sm="6" lg="4">
        <card class="nk-ecwg bg-info-dim p-3" no-padding>
          <card-title-group :card-title="$t('Converted')"></card-title-group>
          <card-data :amount="statistics.converted_leads">
            <template v-slot:info>
              <span>{{$t('pages.ap_agent_profile.appointments_desc')}}</span>
            </template>
          </card-data>
        </card>
      </column>
      <column sm="6" lg="4">
        <card class="nk-ecwg bg-secondary-dim p-3" no-padding>
          <card-title-group :card-title="$t('Success Rate')"></card-title-group>
          <card-data :amount="statistics.success_rate">
            <template v-slot:info>
              <span>{{$t('Conversion success rate')}}</span>
            </template>
          </card-data>
        </card>
      </column>
    </row>
    <row class="mt-5">
      <column default="12">
        <card no-padding full-height>
          <template v-slot:other>
            <card-title-group :card-title="$t('Leads by status')" class="mb-4">

            </card-title-group>
            <special-table class="is-loose">
              <table-row is-heading>
                <table-col><span>{{ $t('Status')}}</span></table-col>
                <table-col class="text-center"><span>{{$t('Count')}}</span></table-col>
                <table-col break-point="sm"><span>%</span></table-col>
              </table-row>
              <table-row v-for="(st,index) in statistics.by_lead_status" :key="'apl_st_tr'+index">
                <table-col>
                  <span>{{ st.status_text }}</span>
                </table-col>
                <table-col class="text-center">{{st.total}}</table-col>
                <table-col class="text-center" break-point="sm">
                  <progressbar :progress="parseFloat(st.percentage)" show-progress-amount alt bg="transparent" height="md"></progressbar>
                </table-col>
              </table-row>
            </special-table>
          </template>
        </card>
      </column>
    </row>
  </div>
</template>

<script>

import {Column, Row} from "@core/layouts";
import {Card, CardData, CardTitleGroup} from "@core/components/cards";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import Progressbar from "@core/components/progressbar/Progressbar";
export default {
  components: {Progressbar, TableCol, TableRow, SpecialTable, Card, CardData, CardTitleGroup, Column, Row},
  props: {
    currentTab: String,
    statistics:{
      type: Object,
      required: true,
    },
  },
}
</script>
