<template>
  <div class="nk-block-head px-4">
    <h5>{{$t('Appointments')}}</h5>
    <p>{{$t('pages.ap_agent_profile.appointments_desc')}}</p>
  </div>
  <div v-if="loading" class="text-center">
    <ion-spinner></ion-spinner>
  </div>
  <div v-else>
  <div class="card-inner p-0">
    <special-table>
      <table-row is-heading>
        <table-col>
          <span class="sub-text">Lead#</span>
        </table-col>
        <table-col>
          <span class="sub-text">Lead</span>
        </table-col>
        <table-col break-point="md">{{ $t('Assigned to') }}</table-col>
        <table-col break-point="md">{{ $t('Assign date') }}</table-col>
        <table-col class="nk-tb-col-tools"></table-col>
      </table-row>
      <table-row v-for="lead in appointments.records" :key="'ld'+lead.id">
        <table-col>{{ lead.lead_number }}</table-col>
        <table-col>
          <user-small-card
              :user-name="lead.first_name+' '+lead.last_name"
              :user-email="lead.email" :image="lead.dp" :initials="lead.initials"></user-small-card>
        </table-col>
        <table-col break-point="md">
          <user-small-card v-if="lead.assigned_to.first_name"
              :user-name="lead.assigned_to.first_name+' '+lead.assigned_to.last_name"
              :user-email="lead.assigned_to.email" :image="lead.assigned_to.dp" :initials="lead.assigned_to.initials"></user-small-card>
          <span class="text-muted" v-else-if="lead.status_id !== 9">{{$t('Not yet assigned')}}</span>
        </table-col>
        <table-col break-point="md">{{ lead.assigned_to.assign_date ? lead.assigned_to.assign_date : '--' }}</table-col>
        <table-col class="nk-tb-col-tools">
          <ul class="nk-tb-actions gx-1">
            <li>
              <drop-down-list>
                <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
              </drop-down-list>
            </li>
          </ul>
        </table-col>
      </table-row>

    </special-table>

  </div>
  <div class="card-inner" v-if="appointments.total_records > 10">
    <pagination
        v-model="appointments.current_page"
        :records="appointments.total_records"
        :per-page="10"
        @paginate="paginationCallback"
        theme="bootstrap4"
        :options="{chunk: 5, texts:{count: '||'}}"/>
  </div>
  </div>
</template>

<script>
import Pagination from "v-pagination-3"
import {reactive, ref, watch} from "vue";
import axios from "@/libs/axios";

import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import {IonSpinner} from "@ionic/vue"

export default {
  components: {
    DropDownList,
    IonSpinner,
    ListItem,
    Pagination,
    SpecialTable,
    TableRow,
    TableCol,
    UserSmallCard,
  },
  props: {
    currentTab: String,
    userId: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  setup( props ){

    let appointments = reactive({
      records: [],
      total_records: 0,
      current_page: 1,
    })
    let loading = ref(false)

    const getAppointments = () => {
      loading.value = true
      axios.post('/api/admin/ap_agent/'+props.userId+'/appointments')
          .then( resp => {
            if(resp.data.success){
              appointments.records = resp.data.data.records
              if(appointments.current_page === 1){
                appointments.total_records = resp.data.data.total_records
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      .then(()=> loading.value = false)
    }

    watch( () => props.currentTab, (n) => {
      if(n === 'appointments' && !appointments.records.length){
        getAppointments()
      }
    })

    return {
      appointments,
      loading,
    }

  }
}
</script>
