<template>
  <div class="nk-block-head px-4">
    <h5>{{$t('Assigned')}} Leads</h5>
    <p>{{$t('pages.ap_agent_profile.assigned_lead_desc')}}</p>
  </div>
  <div v-if="loading" class="text-center">
    <ion-spinner></ion-spinner>
  </div>
  <div v-else>
  <div class="card-inner p-0">
    <special-table>
      <table-row is-heading>
        <table-col>
          <span class="sub-text">Lead#</span>
        </table-col>
        <table-col>
          <span class="sub-text">Lead</span>
        </table-col>
        <table-col break-point="mb">{{ $t('Phone') }}</table-col>
        <table-col break-point="md">{{ $t('Assign date') }}</table-col>
        <table-col break-point="lg">{{ $t('Opened') }}</table-col>
        <table-col break-point="lg">{{ $t('Status') }}</table-col>
        <table-col class="nk-tb-col-tools"></table-col>
      </table-row>
      <table-row v-for="lead in assignedLeads.data" :key="'ld'+lead.id">
        <table-col>{{ lead.lead_number }}</table-col>
        <table-col>
          <user-small-card
              :user-name="lead.first_name+' '+lead.last_name"
              :user-email="lead.email" :image="lead.dp" :initials="lead.initials"></user-small-card>
        </table-col>
        <table-col break-point="mb">
          <span class="tb-lead">{{ lead.phone_no }}</span>
        </table-col>
        <table-col break-point="md">{{ lead.assign_date }}</table-col>
        <table-col break-point="lg">{{ lead.opened_time }}</table-col>
        <table-col break-point="lg">{{ lead.status_text }}</table-col>
        <table-col class="nk-tb-col-tools">
          <ul class="nk-tb-actions gx-1">
            <li>
              <drop-down-list>
                <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                <list-item icon="ni-curve-left-down" :text="$t('Withdraw')" v-on:click="F_withdrawAppointment([lead.AL_id])"></list-item>
              </drop-down-list>
            </li>
          </ul>
        </table-col>
      </table-row>

    </special-table>

  </div>
  <div class="card-inner" v-if="assignedLeads.total_records > 10">
    <pagination
        v-model="assignedLeads.current_page"
        :records="assignedLeads.total_records"
        :per-page="10"
        @paginate="paginationCallback"
        theme="bootstrap4"
        :options="{chunk: 5, texts:{count: '||'}}"/>
  </div>
  </div>
</template>

<script>
import Pagination from "v-pagination-3"
import {reactive, ref, watch} from "vue";
import axios from "@/libs/axios";
import {IonSpinner} from "@ionic/vue"

import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import useAdminLeadsFunctions from "@core/comp-functions/admin/AdminLeadFunctions";

export default {
  components: {
    DropDownList,
    IonSpinner,
    ListItem,
    Pagination,
    SpecialTable,
    TableRow,
    TableCol,
    UserSmallCard,
  },
  props: {
    currentTab: String,
    userId: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  setup( props ){

    const{withdrawLeads} = useAdminLeadsFunctions()
    let assignedLeads = reactive({
      records: [],
      total_records: 0,
      current_page: 1,
    })
    let loading = ref(false)

    const getAssignedLeads = () => {
      loading.value = true
      axios.post('/api/admin/ap_agent/'+props.userId+'/leads')
          .then( resp => {
            if(resp.data.success){
              assignedLeads.records = resp.data.data.records
              if(assignedLeads.current_page === 1){
                assignedLeads.total_records = resp.data.data.total_records
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      .then(()=> loading.value = false)
    }

    const F_withdrawAppointment = async ( AL_ids ) => {
      withdrawLeads(AL_ids, 'appointment').then(ids=>{
        // Remove the selected leads from the leads array
        for(let x in ids){
          for(let y in assignedLeads.records){
            if(assignedLeads.records[y].AL_id === ids[x]){
              assignedLeads.records.splice(y,1)
              break;
            }
          }
        }
        // All deleted, no entry left in the records array? and previous pages exists
        if( !assignedLeads.records.length && assignedLeads.current_page > 1 ){
          // Deduct from total records value
          assignedLeads.total_records -= ids.length
          // Goto previous page
          assignedLeads.current_page--
          getAssignedLeads()
        }
      }).catch(()=>{})
    }

    watch( () => props.currentTab, (n) => {
      if(n === 'leads' && !assignedLeads.records.length){
        getAssignedLeads()
      }
    })

    return {
      assignedLeads,
      F_withdrawAppointment,
      loading,
    }

  }
}
</script>
