<template>
  <page-template>
    <main-block>
      <div v-if="loadingData" class="mb-4">
        <ion-skeleton-text style="width: 25%;height: 30px" animated></ion-skeleton-text>
        <ion-skeleton-text style="width: 25%;height: 30px" animated></ion-skeleton-text>
      </div>
      <block-head v-else :title="$t('Appointment Agents')+' /' ">
        <template v-slot:title_extras>
          <strong class="text-primary small">{{ userData.info.first_name+' '+userData.info.last_name}}</strong>
        </template>
        <template v-slot:description_extras>
          <list-inline>
            <list-inline-item>{{$t('Agent')}} ID: <span class="text-base">{{userData.info.id}}</span></list-inline-item>
            <list-inline-item>{{$t('Last Login')}}: <span class="text-base">{{userData.info.last_login.date}}</span></list-inline-item>
          </list-inline>
        </template>
        <template v-slot:default>
          <nk-button type="primary" outline v-on:click="$router.go(-1)"><nio-icon icon="ni-arrow-left"></nio-icon> {{$t('Back')}}</nk-button>
        </template>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <card-aside sidebar-position="right" :aside-active="asideActive" @toggle-sidebar="asideStatus">
            <template v-slot:contentWrapper>
              <nav-tabs tab-icons nav-tabs-card>
                <template v-slot:tabs>
                  <nav-tab-item
                      v-for="(tab,index) in tabs"
                      :tab-id="index"
                      :tab-name="tab.name"
                      :icon="tab.icon"
                      :key="'tb'+index"
                      :current-tab="currentTab"
                      @switch-tab="switchTab"></nav-tab-item>
                  <nav-tab-item tab-name="" tab-id="t_action" icon="ni-user-list-fill" class="nav-item-trigger d-xxl-none" v-on:click="asideActive=true"></nav-tab-item>
                </template>
                <template v-slot:content>
                  <div class="py-4 text-center" v-if="loadingData">
                    <ion-spinner></ion-spinner>
                  </div>
                  <template v-else>
                    <nav-tab-pane tab-id="p_info" :class="{active: currentTab === 'p_info'}" class="nav-tabs-card">
                      <personal-info :user-data="userData.info"></personal-info>
                    </nav-tab-pane>
                    <nav-tab-pane tab-id="leads" :class="{active: currentTab === 'leads'}">
                      <assigned-leads :current-tab="currentTab" :user-id="userData.info.id"></assigned-leads>
                    </nav-tab-pane>
                    <nav-tab-pane tab-id="appointments" :class="{active: currentTab === 'appointments'}">
                      <appointments :current-tab="currentTab" :user-id="userData.info.user_id"></appointments>
                    </nav-tab-pane>
                    <nav-tab-pane tab-id="activities" :class="{active: currentTab === 'activities'}" class="nav-tabs-card">
                      <activity-log :current-tab="currentTab" :user-id="userData.info.user_id"></activity-log>
                    </nav-tab-pane>
                    <nav-tab-pane tab-id="activities" :class="{active: currentTab === 'statistics'}" class="nav-tabs-card">
                      <statistics :current-tab="currentTab" :statistics="userData.stats"></statistics>
                    </nav-tab-pane>
                  </template>
                </template>
              </nav-tabs>
            </template>
            <template v-slot:aside>
              <div class="py-5 text-center" v-if="loadingData">
                <ion-spinner></ion-spinner>
              </div>
              <card-inner-group v-else>
                <card-inner>
                  <user-card :name="userData.info.first_name+' '+userData.info.last_name" :badge-text="$t('Appointment Agent')" :image="userData.info.dp" :initials="userData.info.initials">
                    <template v-slot:extras>
                      <span class="sub-text">{{userData.info.email}}</span>
                    </template>
                  </user-card>
                </card-inner>
                <card-inner class="card-inner-sm">
                  <btn-toolbar class="justify-center">
                    <btn-toolbar-item>
                      <nk-button type="" is-link class="btn-trigger" is-icon-only-button>
                        <nio-icon icon="ni-mail"></nio-icon>
                      </nk-button>
                    </btn-toolbar-item>
                    <btn-toolbar-item>
                      <nk-button v-on:click="F_changeAccountStatus([userData.info])" type="" is-link class="btn-trigger"
                                 v-tooltip="$t('Change status')"
                                 :class="userData.info.user_status === 0 ? 'text-success': 'text-danger'" is-icon-only-button>
                        <nio-icon :icon="userData.info.user_status === 0 ? 'ni-user-check-fill' : 'ni-user-cross-fill'"></nio-icon>
                      </nk-button>
                    </btn-toolbar-item>
                  </btn-toolbar>
                </card-inner>
                <card-inner>
                  <row class="text-center">
                    <column default="4">
                      <profile-stats :label="$t('Assigned leads')" :value="userData.stats.assigned_leads"></profile-stats>
                    </column>
                    <column default="4">
                      <profile-stats :label="$t('Converted')" :value="userData.stats.converted_leads"></profile-stats>
                    </column>
                    <column default="4">
                      <profile-stats :label="$t('Success rate')" :value="userData.stats.success_rate"></profile-stats>
                    </column>
                  </row>
                </card-inner>
                <card-inner>
                  <h6 class="overline-title-alt mb-2">{{ $t('Additional') }}</h6>
                  <row class="g-3">
                    <column default="6">
                      <span class="sub-text">{{$t('User')}} ID:</span>
                      <span class="profile-ud-value">{{ userData.info.id }}</span>
                    </column>
                    <column default="6">
                      <span class="sub-text">{{$t('Last Login')}}:</span>
                      <span class="profile-ud-value">{{ userData.info.last_login.date }}</span>
                    </column>
                    <column default="6">
                      <span class="sub-text">{{$t('Account')}}:</span>
                      <span :class="{'text-success' :userData.info.user_status === 1, 'text-danger' : userData.info.user_status === 0}">
                            {{ userData.info.user_status === 1 ? $t('Active') : userData.info.user_status === 0 ? $t('Inactive') : '' }}
                          </span>
                    </column>
                    <column default="6">
                      <span class="sub-text">{{$t('Registered')}} {{$t('At')}}:</span>
                      <span class="profile-ud-value">{{ userData.info.created_at }}</span>
                    </column>
                  </row>
                </card-inner>
              </card-inner-group>
            </template>
          </card-aside>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {onIonViewWillEnter, IonSkeletonText, IonSpinner} from '@ionic/vue';
import {defineComponent, reactive, ref} from 'vue';
import {BlockContent, BlockHead, Column, MainBlock, Row} from "@core/layouts";
import axios from "@/libs/axios"
import {Card, CardInner, CardInnerGroup} from "@core/components/cards";

import "bootstrap"

import {useRoute} from "vue-router"
import {ListInline, ListInlineItem} from "@core/components/list-inline";
import CardAside from "@core/layouts/card-aside/CardAside";
import {NavTabItem, NavTabPane, NavTabs} from "@core/components/nav-tabs";

import UserCard from "@/views/admin/advisors/components/UserCard"
import {BtnToolbar, BtnToolbarItem} from "@core/components/btn-toolbar";
import ProfileStats from "@/views/admin/advisors/components/ProfileStats";
import {Appointments, AssignedLeads, PersonalInfo, Statistics} from "./components";
import ActivityLog from "@/views/admin/advisors/components/ActivityLog";
import {useI18n} from "vue-i18n";
import useCommonFunc from "@/@core/comp-functions/common"
import useUser from "@core/comp-functions/User"
import PageTemplate from "@core/layouts/page/PageTemplate";


export default defineComponent({
  components: {
    PageTemplate,
    Statistics,
    Appointments,
    AssignedLeads,
    ActivityLog,
    PersonalInfo,
    ProfileStats,
    Column,
    Row,
    BtnToolbarItem,
    BtnToolbar,
    CardInner,
    NavTabPane,
    NavTabItem,
    NavTabs,
    CardAside,
    ListInlineItem,
    ListInline,
    Card,
    CardInnerGroup,
    BlockContent,
    MainBlock,
    BlockHead,
    UserCard,
    IonSkeletonText,
    IonSpinner,
  },
  setup() {

    const{updateObject} = useCommonFunc()
    const{changeAccountStatus} = useUser()
    const{t} = useI18n()
    const route  = useRoute()

    let loadingData = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      //await getAdvisors(true)
    }

    let userData = reactive({
      info:{
        house_no: "",
        city: "",
        created_at: "",
        dp: "",
        email: ".",
        first_name: "",
        id: 0,
        initials: '',
        last_login: {date: "", text: ""},
        last_name: "",
        phone_number: "",
        post_code: "",
        province: "",
        street: '',
        updated_at: "",
        user_id: 0,
        user_status: -1,
      },
      stats: {
        assigned_leads: '-',
        converted_leads: '-', // Into appointments
        success_rate: '-',
        by_lead_status: [],
        rank: '',
      },
    })
    const getProfile = () => {
      loadingData.value = true
      axios.get('/api/admin/ap_agent/'+route.params.id+'/profile')
          .then( resp => {
            if(resp.data.success){
              updateObject(userData, resp.data.data)
            }
          })
          .catch(error => {
            console.log(error)
          })
      .then(()=> loadingData.value = false)
    }
    onIonViewWillEnter(() => {
      getProfile()
      currentTab.value = route.params.tab || 'p_info'
    })

    // Tabs
    let asideActive = ref(false)
    const asideStatus = () => {
      asideActive.value = !asideActive.value
    }
    let currentTab = ref( (route.params.tab ? route.params.tab : 'p_info') )
    const switchTab = (toTab) => {
      currentTab.value = toTab
      asideActive.value = false
    }

    const tabs = {
      p_info: {
        name: t('Information'),
        icon: 'ni-user-circle',
      },
      leads: {
        name: 'Leads',
        icon: 'ni-coin-alt-fill',
      },
      appointments: {
        name: t('Appointments'),
        icon: 'ni-calendar-check',
      },
      activities: {
        name: t('Activities'),
        icon: 'ni-activity',
      },
      statistics: {
        name: t('Statistics'),
        icon: 'ni-growth-fill',
      },
    }

    const F_changeAccountStatus = ( users, action ) => {
      changeAccountStatus(users, 'appointment agent', action)
          .then(res =>{
            userData.info.user_status = res.status
          }).catch(()=>{})
    }

    return {
      switchTab,
      asideActive,
      asideStatus,
      currentTab,
      tabs,
      F_changeAccountStatus,

      loadingData,

      pagination,
      paginationCallback,
      userData,
    }
  }
});

</script>
