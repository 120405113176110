<template>
  <div class="progress" :class="progressClasses">
    <div class="progress-bar" role="progressbar" :aria-valuenow="progress" :aria-valuemin="0" :aria-valuemax="100" :data-progress="progress" :class="progressbarStyle.classes"
         :style="progressbarStyle.style">

    </div>
    <div class="progress-amount" v-if="showProgressAmount">{{progress}}%</div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  props: {
    progress: {
      required: true,
      type: Number,
    },
    color: String,
    showProgressAmount: Boolean,
    height: String, // sm, md, lg
    bg: String, // transparent, danger etc.
    alt: Boolean, // progress-alt
  },
  setup(props){

    const progressbarStyle = computed(()=>{
      let t = {classes:[], style:{width: props.progress+'%'}}
      if( props.color){
        props.color.match(/^(rgb|#)/) ? (t.style['background-color']=props.color) : t.classes.push(`bg-${props.color}`)
      }
      return t
    })

    const progressClasses = computed(()=>{
      let classesList = []
      if(props.height){
        classesList.push(`progress-${props.height}`)
      }
      if(props.bg){
        classesList.push(`bg-${props.bg}`)
      }
      if(props.alt){
        classesList.push('progress-alt')
      }
      return classesList
    })
    return {
      progressbarStyle,
      progressClasses,
    }
  }
}
</script>
